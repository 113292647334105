import VueGoogleMaps from "@fawmi/vue-google-maps";

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyBL7h3NlcZoPi_F5qlUhOSZ_YOPf7kay04",
      libraries: "places",
    },
  });
});
