<script>
import 'vue-select/dist/vue-select.css';
import "vue-toastification/dist/index.css";
export default {
  data: () => ({
    data_loading: false,
    collected_today: null,
    collected_this_month: null,
  }),
  methods: {
    layout() {
      if (this.$route.name == 'organizations-pages-collection-preview') {
        return 'pages'
      } else if (this.$route.name != undefined) {
        switch (true) {
          case this.$route.name.startsWith('admin-'):
            return 'admin'
          case this.$route.name.startsWith('organizations-'):
            return 'organizations'
          case this.$route.name.startsWith('donors-'):
            return this.$route.name == 'donors-token' ? 'auth' : 'donors'
          case this.$route.name == 'keren-hatorah':
            return 'mir'
          default:
            return 'auth'
        }
      }
    }
  },
}
</script>
<template>
  <NuxtLoadingIndicator :throttle="200" color="#f37a4f" :height="5" />
  <NuxtLayout :name="layout()">
    <NuxtPage />
  </NuxtLayout>
</template>