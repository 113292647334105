import { defineStore } from "pinia";

export const useOrganizationsAuthStore = defineStore(
  "organizationsAuth",
  () => {
    const manager = persistedState.localStorage.getItem("manager");
    const organization = persistedState.localStorage.getItem("organization");
    const slug = persistedState.localStorage.getItem("slug");
    const qr_code = persistedState.localStorage.getItem("qr_code");
    const related = persistedState.localStorage.getItem("related");

    watch(
      () => manager,
      (state) => {
        localStorage.setItem("manager", JSON.stringify(state));
      },
      { deep: true }
    );

    watch(
      () => organization,
      (state) => {
        localStorage.setItem("organization", JSON.stringify(state));
      },
      { deep: true }
    );

    watch(
      () => slug,
      (state) => {
        localStorage.setItem("slug", JSON.stringify(state));
      },
      { deep: true }
    );

    watch(
      () => qr_code,
      (state) => {
        localStorage.setItem("qr_code", JSON.stringify(state));
      },
      { deep: true }
    );

    watch(
      () => related,
      (state) => {
        localStorage.setItem("related", JSON.stringify(state));
      },
      { deep: true }
    );

    async function logIn(manager) {
      window.localStorage.clear();
      await organizationsFetch("/auth", {
        method: "POST",
        body: {
          email: manager.email,
          password: manager.password,
        },
      }).then((response) => {
        if (response.authenticated) {
          persistedState.localStorage.setItem("manager", response.manager);
          persistedState.localStorage.setItem(
            "organization",
            response.organization
          );
          persistedState.localStorage.setItem("slug", response.slug);
          persistedState.localStorage.setItem("qr_code", response.qr_code);
          persistedState.localStorage.setItem(
            "related",
            JSON.stringify(response.related)
          );
          this.manager = persistedState.localStorage.getItem("manager");
          this.organization =
            persistedState.localStorage.getItem("organization");
          this.slug = persistedState.localStorage.getItem("slug");
          this.qr_code = persistedState.localStorage.getItem("qr_code");
          this.related = persistedState.localStorage.getItem("related");
          this.logo = response.logo;
          persistedState.localStorage.setItem("token", response.token);
        } else {
          window.localStorage.clear();
        }
      });
    }
    function logOut() {
      window.localStorage.clear();
      window.location.reload();
    }
    function currentToken() {
      return persistedState.localStorage.getItem("token");
    }
    function currentSlug() {
      return persistedState.localStorage.getItem("slug");
    }
    function currentQrCode() {
      return persistedState.localStorage.getItem("qr_code");
    }
    function setCurrentSlug(slug) {
      persistedState.localStorage.setItem("slug", slug);
    }
    function setCurrentQrCode(qr_code) {
      persistedState.localStorage.setItem("qr_code", qr_code);
    }
    function setCurrentAccount(account) {
      persistedState.localStorage.setItem("slug", account.slug);
      persistedState.localStorage.setItem("qr_code", account.qr_code);
      persistedState.localStorage.setItem("organization", account.name);
      window.location.reload();
    }
    function getRelated() {
      return JSON.parse(persistedState.localStorage.getItem("related"));
    }

    return {
      manager,
      organization,
      slug,
      qr_code,
      setCurrentSlug,
      setCurrentQrCode,
      setCurrentAccount,
      related,
      logIn,
      logOut,
      currentToken,
      currentSlug,
      currentQrCode,
      getRelated,
    };
  }
);
